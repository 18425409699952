import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export default function PetCareImage() {
  return (
    <div className="relative">
      <div className="pt-24">
        <StaticImage
          src="../images/pets.jpg"
          className="block w-full object-cover"
          alt="St. Thomas and London House Sitting Service"
          height={600}
        />
        <div className="absolute inset-0 bg-gray-100 mix-blend-multiply" />
      </div>
      <div className="absolute inset-0">
        <div className="text-brand-black flex h-full  w-full items-start justify-center pb-4 pt-10 text-3xl font-semibold md:text-4xl lg:text-6xl">
          <div className="w-11/12 text-center md:w-10/12 lg:w-9/12 xl:w-7/12">
            HomeSafe Pet Care
          </div>
        </div>
      </div>
    </div>
  );
}
