import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const people = [
  {
    name: 'Lindsay Walton',
    role: 'Front-end Developer',
    imageUrl:
      'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  // More people...
];

export default function PetsBar() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="mx-auto space-y-5 text-center sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
              SMALL ANIMALS OR REPTILES
            </h2>
            <p className="text-xl text-gray-500">
              (Please contact for pricing)
            </p>
          </div>
          <ul
            role="list"
            className="grid grid-cols-2 space-y-12 sm:grid-cols-4 sm:gap-x-12 sm:gap-y-12 sm:space-y-0 lg:grid-cols-4 lg:gap-x-32"
          >
            <li>
              <div className="space-y-4">
                <div className="aspect-w-3 aspect-h-2">
                  {/* <img
                    className="rounded-lg object-cover shadow-lg"
                    src={person.imageUrl}
                    alt=""
                  /> */}
                  <StaticImage
                    src="../images/fishbowl.jpg"
                    className="block w-full object-cover"
                    alt="St. Thomas and London House Sitting Service"
                    height={600}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="space-y-4">
                <div className="aspect-w-3 aspect-h-2">
                  {/* <img
                    className="rounded-lg object-cover shadow-lg"
                    src={person.imageUrl}
                    alt=""
                  /> */}
                  <StaticImage
                    src="../images/rabbit.jpg"
                    className="block w-full object-cover"
                    alt="St. Thomas and London House Sitting Service"
                    height={600}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="space-y-4">
                <div className="aspect-w-3 aspect-h-2">
                  {/* <img
                    className="rounded-lg object-cover shadow-lg"
                    src={person.imageUrl}
                    alt=""
                  /> */}
                  <StaticImage
                    src="../images/hamster.jpg"
                    className="block w-full object-cover"
                    alt="St. Thomas and London House Sitting Service"
                    height={600}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="space-y-4">
                <div className="aspect-w-3 aspect-h-2">
                  <StaticImage
                    src="../images/lizard.jpg"
                    className="block w-full object-cover"
                    alt="St. Thomas and London House Sitting Service"
                    height={600}
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
