import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import Contact from '../components/contact';
import CTA from '../components/cta';
import DidYouKnow from '../components/didYouKnow';
import Header from '../components/header';

import Layout from '../components/layout';
import LogoBar from '../components/logobar';
import PetCareImage from '../components/petCareImage';
import PetCarePrices from '../components/petCarePrices';
import PetsBar from '../components/petsBar';
import Seo from '../components/seo';

function PetCare() {
  const dogtiers = [
    {
      name: 'Walks',

      freq: 'walk',
      priceMonthly: 25,
      description:
        'Each walk is approximately 15 minutes around your neighbourhood. Walks can provide your dog with more than a bathroom break, it can give them physical exercise, mental stimulation, and time to sniff and explore.',
    },
    {
      name: 'Drop in',

      freq: 'visit',
      priceMonthly: 20,
      description:
        'Create a custom drop-in schedule for your dog that is tailored to your pet(s) needs. These drop in needs could encompass a potty break, food top up, and even added snuggle time.',
    },
    {
      name: 'Overnight',
      freq: 'night',
      priceMonthly: 60,
      description:
        'Don’t like Kennels? Prefer to keep your fur baby happy at home while you are away? Keeping your dogs at home while you are away is what most dog owners and pets prefer. Our team will stay late afternoon to early morning making sure your dog is pampered and kept to their daily routine. The snuggles are included.',
    },
  ];
  const cattiers = [
    {
      name: 'Drop in',

      freq: 'visit',
      priceMonthly: 20,
      description:
        'Cats are very independent and like to stay in a familiar environment when their owners go away. The daily drop in is a necessity for food and water change, litter box clean out, and daily snuggles. The drop ins are customized between our team member, the pet owner, the needs and requirements and the normal routine of the cat.',
    },
    {
      name: 'Overnight',

      freq: 'night',
      priceMonthly: 30,
      description:
        'Overnight stays are perfect for an older cat or a kitten. Keeping an older cat or kitten in their familiar environment, while you are away, prevents any traumatic behaviour. Overnights scheduling is created between the HomeSafe pet caregiver and the owner.',
    },
  ];
  return (
    <Layout>
      <Seo
        title="Pet Care Service"
        description="HomeSafe pet care will treat your
              furry friend to an amazing and affordable time at home."
      />
      <LogoBar slogan="Trusted & Reliable Pet Care Service" />
      <PetCareImage />
      <div className="bg-gray-900">
        <div className="px-6 py-12 lg:px-8 lg:py-20">
          <div className="text-center">
            <p className="mt-2 text-3xl font-semibold tracking-tight text-white sm:text-4xl lg:text-5xl">
              Can’t bring your pets with you on vacation?
            </p>
            <p className="mx-auto mt-3 max-w-5xl text-xl text-gray-300 sm:mt-5 sm:text-2xl">
              Book your pets a relaxing staycation with our trusted, reliable,
              bonded, Insured and caring team. HomeSafe pet care will treat your
              furry friend to an amazing and affordable time at home.
            </p>
            <p className="mx-auto mt-3 max-w-5xl text-xl text-gray-300 sm:mt-5 sm:text-2xl">
              Our pet service team consists of the best hand-picked caring
              individuals that offer a full suite of pet services created with
              your fur baby’s well-being in mind.
            </p>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col">
        <div className="order-last lg:order-first">
          <PetCarePrices
            animal="Dog"
            addon="Additional dogs: $10 each /per visit"
            tiers={dogtiers}
          />
        </div>

        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:mt-36 lg:h-fit lg:w-1/2">
          <StaticImage
            src="../images/dog-care.jpg"
            width={480}
            height={640}
            className="mx-auto mt-12 w-10/12 object-cover sm:w-6/12 sm:py-24  lg:w-3/4 lg:py-0"
          />
        </div>
      </div>
      <div className="relative flex flex-col">
        <div className="order-last lg:order-first">
          <PetCarePrices
            animal="Cat"
            addon="Additional cats: $5 each /per visit"
            tiers={cattiers}
          />
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:mt-36 lg:h-fit lg:w-1/2">
          <StaticImage
            src="../images/cat-care.jpg"
            width={480}
            height={640}
            className="mx-auto mt-12 w-10/12 object-cover sm:w-6/12 sm:py-24  lg:w-3/4 lg:py-0"
          />
        </div>
      </div>
      <CTA />
      {/* <DidYouKnow title="Inquire about our House and Pet Care bundles" /> */}
      <PetsBar />
      <Contact />
    </Layout>
  );
}

export default PetCare;
