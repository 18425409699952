import React, { Fragment } from 'react';

import { CheckIcon, MinusIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function PetCarePrices({ animal, addon, tiers }) {
  return (
    <div className="bg-white">
      <div className="bg-white py-16 sm:py-24 lg:w-1/2">
        {/* xs to lg */}

        <div className="mx-auto flex flex-col items-center justify-center ">
          <h3 className="flex w-10/12 justify-start pb-12 text-4xl font-bold tracking-tight text-gray-900 sm:w-6/12 ">
            {animal} Care Services
          </h3>
          {tiers.map((tier, tierIdx) => (
            <section key={tier.tierIdx} className="flex w-10/12 sm:w-6/12">
              <div className="mb-8 ">
                <h2 className="text-lg font-medium leading-6 text-brand-blue">
                  {tier.name}
                </h2>
                <p className="mt-4">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    ${tier.priceMonthly}
                  </span>{' '}
                  <span className="text-base font-medium text-gray-500">
                    /{tier.freq}
                  </span>
                </p>
                <p className="text-md mt-4 text-gray-500">{tier.description}</p>
              </div>
            </section>
          ))}
          <div className="flex w-10/12 flex-col sm:w-6/12 ">
            <p className="text-md mt-3  justify-start text-left text-gray-600">
              Pricing Based on One {animal}
            </p>
            <p className="text-md mt-3 text-gray-500">{addon}</p>
            <p className="text-md mt-3 text-gray-500">
              * in the event of more than one pet, dogs will be priced first.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
